export const DEV = [
  {
    id: 10,
    title: "🍒 Web-portfolio",
    description: "Personal website to show all of my projects and skills",
    link: ["https://github.com/Ryyyyyy12/ryyyyyy"],
    coverImg: "./assets/images/projectImg/webport.png",
    tools: [
      "React",
      "TypeScript",
      "Figma",
      "tailwindcss",
      "Bootstrap",
      "ReactStrap",
      "MUI",
      "AOS",
    ],
  },
  {
    id: 99,
    title: "🐜 ModLifes",
    description:
      "CS22 Integrated project I, Application for KMUTT's student including 20 features such as announcement, notification, chat, ToDoList, Airdrop, Dating, group, Q&A, Restaurant, Shop, ShopReview, Shortlink, ShortNote, etc.",
    link: ["https://github.com/CSSIT22/studentlife"],
    coverImg: "./assets/images/projectImg/modlifes/mod.png",
    tools: [
      "React",
      "TypeScript",
      "chakra-UI",
      "Figma",
      "PrismaORM",
      "Axios",
      "SocketIO",
      "NodeMailer",
    ],
    info: {
      res: [
        "Worked as a team leader for the notification module",
        "Collaborated with 60+ students",
        "Developed both the frontend and backend of the notification module",
        "Designed the database and created the ER Diagram for the notification module",
        "Designed the UI for the notification module",
        "Integrated the notification module with 10 other modules",
        "Developed real-time notification using socket.io",
        "Implemented email notification using nodemailer",
      ],
    },
  },
  {
    id: 1,
    title: "🏘️ Moddormmy",
    description:
      "CSC234 User-Centered Mobile App. Dev. & CSC231 Agile Software Engineering, Application for KMUTT's student to find dormitory.",
    link: [
      "https://github.com/KNattawat89/ModDormy",
      "https://github.com/KNattawat89/Moddormy_backend",
    ],
    coverImg: "./assets/images/projectImg/moddormmy/ui.png",
    tools: ["Flutter", "Golang", "Fiber", "Gorm", "Firebase", "Mysql", "Figma"],
    info: {
      res: [
        "Designed UX-UI of application",
        "Developed both frontend and backend for the review feature",
      ],
    },
  },
  {
    id: 2,
    title: "✨ Gift2Grow",
    description:
      "CS22 Integrated project II, Application for donate education items to children in need.",
    link: [
      "https://github.com/nichakontae/gift2grow",
      "https://github.com/nichakontae/gift2grow_backend",
    ],
    coverImg: "./assets/images/projectImg/gift2grow/ui.png",
    tools: [
      "Flutter",
      "Dart",
      "Firebase",
      "Fiber",
      "Gorm",
      "mySql",
      "Figma",
      "Golang",
    ],
    info: {
      res: [
        "Designed UX-UI of application",
        "Developed both frontend and backend for notification feature",
        "Developed Database",
        "Design ER Diagram for notification feature",
        "Implemented real-time push notification and in-app notification using firebase cloud messaging",
      ],
    },
  },
  {
    id: 3,
    title: "🤖 DiscordBot",
    description:
      "Discord bot for real-time announcement grade from KMUTT's system via discord bot DM.",
    link: ["https://github.com/Ryyyyyy12/discordBot"],
    coverImg: "./assets/images/projectImg/bot/announce.png",
    tools: ["Golang"],
  },
  {
    id: 4,
    title: "📸 Instagram Clone",
    description:
      "Flutter workshop, Frontend Instagram Clone. This project is for gaining hands-on experience in using Flutter.",
    link: ["https://github.com/Ryyyyyy12/flutter-clone-instagram"],
    coverImg: "./assets/images/projectImg/igclone.png",
    tools: ["Flutter", "Dart"],
  },
  {
    id: 5,
    title: "🛵 Lineman Clone",
    description:
      "Flutter workshop, Frontend Lineman Clone. This project is for gaining hands-on experience in using Flutter.",
    link: ["https://github.com/Ryyyyyy12/flutter-clone-lineman"],
    coverImg: "./assets/images/projectImg/lmclone.png",
    tools: ["Flutter", "Dart"],
  },
  {
    id: 6,
    title: "🎧 Spotify Clone",
    description:
      "Frontend Spotify Clone. This project is for gaining hands-on experience in website development.",
    link: ["https://github.com/Ryyyyyy12/react-clone-spotify"],
    coverImg: "./assets/images/projectImg/spotifyclone.png",
    tools: ["React", "JavaScript", "Mui", "HTML", "CSS"],
  },
  {
    id: 7,
    title: "📝 TodoList",
    description:
      "CSC105 Web App Dev. ,Frontend TodoList. This project is for gaining hands-on experience in website development and practice concept of using useState.",
    link: ["https://github.com/Ryyyyyy12/react-todo"],
    coverImg: "./assets/images/projectImg/todo.png",
    tools: ["React", "JavaScript", "Mui", "HTML", "CSS"],
  },
  {
    id: 8,
    title: "🧑‍💻 929 Exam",
    description: "CSC105 Web App Dev. ,929 Exam",
    link: ["https://github.com/Ryyyyyy12/react-929exam"],
    coverImg: "./assets/images/projectImg/929.png",
    tools: ["React", "JavaScript", "HTML", "CSS", "Bootstrap"],
  },
  {
    id: 9,
    title: "📌 Data Structure",
    description: "CSC209 Data Structure Lab",
    link: ["https://github.com/Ryyyyyy12/csc209-data-structure"],
    coverImg: null,
    tools: ["Java"],
  },
];

export const ART = [
  { id: 0, title: "", description: "", img: "", date: "", tools: [""] },
];
